import { useContext, useEffect, useMemo } from "react";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";
import { Input } from "../../../Input/Input";
import { Col, Row } from "react-bootstrap";
import { useSecurity } from "../../../Security/security";

export default function EpoSmartPack() {
  const { validateRole } = useSecurity();
  const { applicationDetails, updateApplicationDetails, isEditable } = useContext(EpoApplicationDetailContext);
  
  const dowloadFile = () => {
    const {SA21data} = applicationDetails;
     if(SA21data){
      const pdfData = new Uint8Array(SA21data.data)
      var fileURL = window.URL.createObjectURL(new Blob([pdfData],{type:'application/pdf'}));
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = applicationDetails.SA21;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      }, 0);
     }
  };
  
  const showDetails = useMemo(() => {
    if (validateRole({ department: "OPS", role: "SUPERUSER" })) {
      if (applicationDetails.Status === "New")
        return true
    } else if (validateRole({ department: "CMT" })) {
      if (applicationDetails.Status === 'New' ) {
       return true
      }
    } else if (validateRole({ department: "FIN" }) && applicationDetails.Status === "Activated") {
      return true
    }
    return false;

  }, [validateRole, applicationDetails.Status]);


  return (
    <div>
      {applicationDetails?.envelopeUrl && (<div className="inputs-group px-4 py-3 mb-4">
        Once a customer has filled in all the necessary information they need to sign our CABIE Pack before we can allow them to submit declarations. Please
        click the link below to review the documents on DocuSign. {showDetails && 'Once you have confirmed the details are accurate and the documents are signed, check the box and move onto the confirmation page.' }
      </div>)}
      {applicationDetails?.SA21data && (<div className="inputs-group px-4 py-3 mb-4">
      This application was added through an internal sign-up. After confirming the details are correct and the documents are signed, please check the box and proceed to the confirmation page.
      </div>)}
      {isEditable && (
      <div className="d-flex justify-content-center">
      {applicationDetails?.SA21data && (
      <a     
      className="blue-button-teams green-btn"
      style={{ borderRadius: "10px", width: "8rem" }}
      rel="noreferrer"
      onClick={() => {
        dowloadFile();
      }}
    >
      CABIE Pack
    </a>)}
       { applicationDetails?.envelopeUrl && (<a
          href={applicationDetails.envelopeUrl}
          target="_blank"
          className="blue-button-teams green-btn"
          style={{ borderRadius: "10px", width: "8rem" }}
          rel="noreferrer"
        >
          CABIE Pack
        </a>)}       
        {/* 
              https://appdemo.docusign.com/documents/details/e49d8203-07be-4ce2-9cc1-f464d019d28b
            */}
      </div>
      )}

      {(showDetails) && (
        <Row>
          <Col>
            <label className="mt-4 mb-4 d-flex align-items-center gap-2 mx-5">
              <input
                type="checkbox"
                name="c1207nForm"
                checked={applicationDetails.smartpackReviewed}
                onClick={(e) => updateApplicationDetails("smartpackReviewed", e.target.checked)}
              />
              <span style={{ fontSize: "90%", color: "hsl(0,0%,40%)" }}>
                I have reviewed the documents from this application and confirm that the they have been signed and the details match that within the rest of the
                application.
              </span>
            </label>
          </Col>
        </Row>
      )}
    </div>
  );
}
