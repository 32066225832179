import { useContext, useState } from "react";
import { Col, OverlayTrigger, PopoverContent, Row, Popover } from "react-bootstrap";
import { Input, Select } from "../../../Input/Input";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";
import { postDocusignResend } from "../../../apiCalls";


export default function EpoCompliance() {
  const { applicationDetails, updateApplicationDetails, isEditable } = useContext(EpoApplicationDetailContext);
  const [resendClicked, setResendClicked] = useState(false);

  const resendEnvelope = () => {
    setResendClicked(true);
    const fetch = async () => {
      const response = await postDocusignResend(applicationDetails.id)
      alert("Envelope sent")
    }
    fetch();
  }

  return (
    <div className="px-4 pb-5 pt-2 bg-white">
      <h6 className="text-eoriblue mt-2">Customs &amp; Compliance</h6>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                UK DAN Account <span style={{ fontSize: "120%" }}></span>
              </>
            }
            value={applicationDetails.dutyDefermentNumber}
            onChange={e => updateApplicationDetails('dutyDefermentNumber', e.target.value)}
            onBlur={e => updateApplicationDetails('authorized', applicationDetails.dutyDefermentNumber !== "")}
            disabled={!isEditable}
          />
        </Col>
        <Col>
          <div className="compliance-checkbox-container">
            <input type="checkbox" name="authorized" className="compliance-checkbox"
              checked={applicationDetails.authorized}
              onChange={(e) => { updateApplicationDetails('authorized', e.target.checked) }}
              disabled
            />
            <label htmlFor="authorized" className="compliance-checkbox-label">Authorised?
              <OverlayTrigger
                placement="right"
                trigger={["hover", "focus"]}
                overlay={
                  <Popover style={{ minWidth: "25rem" }}>
                    <PopoverContent>
                      <p>Has the Nee/Nor authorised the declarant to use the DDA? </p>
                    </PopoverContent>
                  </Popover>
                }
              >
                <span className="info ms-2"></span>
              </OverlayTrigger>
            </label>
          </div>
        </Col>
      </Row>
      <Row className="py-1">
        <Col>
          <Select
            label={
              <>
                Do you hold a CCG?<span style={{ fontSize: "120%" }}></span>
              </>
            }
            data={applicationDetails.CCGHeld}
            setData={e => updateApplicationDetails('CCGHeld', e)}
            options={[{ id: 1, data: "No" }, { id: 2, data: "Yes" }]}
            disabled={!isEditable}
          />
        </Col>
        <Col>
          {[2, '2'].includes(applicationDetails.CCGHeld) &&
            <Input
              label={
                <>
                  CCG Reference <span style={{ fontSize: "120%" }}></span>
                </>
              }
              disabled={!isEditable}
              value={applicationDetails.CCGReference}
              onChange={e => updateApplicationDetails('CCGReference', e.target.value)}
            />
          }
        </Col>
      </Row>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Warehouse ID <span style={{ fontSize: "120%" }}></span>
              </>
            }
            value={applicationDetails.warehouseID}
            onChange={e => updateApplicationDetails('warehouseID', e.target.value)}
            disabled={!isEditable}
          />
        </Col>
        <Col>
          <Input
            label={
              <>
                Supervising Office <span style={{ fontSize: "120%" }}></span>
              </>
            }
            value={applicationDetails.supervisingOffice}
            onChange={e => updateApplicationDetails('supervisingOffice', e.target.value)}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="input-label label-text" style={{ paddingBottom: '10px' }}>
            Envelope Status
          </div>
          <div className="bold d-flex flex-row" style={{
            ...(applicationDetails.envelopeStatus === 'completed' ? { color: 'green' } :
              applicationDetails.envelopeStatus === 'sent' ? { color: 'orange' } : { color: 'red' })
          }}>
            <div>
              {applicationDetails.envelopeStatus || "Loading..."}
            </div>
            <div style={{ marginLeft: '20px' }}>
              {applicationDetails.envelopeStatus && applicationDetails.envelopeStatus !== 'completed' &&
                <button disabled={resendClicked || !isEditable} className="mt-2 activation-email-button" onClick={resendEnvelope}>Resend</button>
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}