import { useEffect, useState, useRef, useContext } from "react";
import { Col, Row, OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { getNewLoad } from "../apiCalls";
import { Input, Select } from "../Input/Input";
import { useStepper } from "../Stepper";
import user from "../Epo/assets/user.png";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";

export default function PersonalDetails() {
  const { stepBackward, localData, setDataByKey, stepForward, activeStepKey } = useStepper();

  const [errors, setErrors] = useState({});

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.firstName) freshErrors.firstName = "Required";
    if (!localData.lastName) freshErrors.lastName = "Required";
    if (!localData.companyPosition) freshErrors.companyPosition = "Required";
    if (!localData.phoneNumber) freshErrors.phoneNumber = "Required";
    if (!localData.emailAddress) freshErrors.emailAddress = "Required";

    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      stepForward();
    }
  };

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "personal" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "0.5. PersonalDetails",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  return (
    <form onSubmit={handleNext} className="flex-grow-1 d-flex flex-column">
      <div>
        <span className="register-step-header">Personal Information</span>
        <div className="inputs-group register-info">
          <img src={user} width="70" height="70" alt="" />
          <span>
            Before you start we just need to get your contact details in case we need to get in touch with you later.
          </span>
        </div>
        <Row className="py-2">
          <Col>
            <Input
              label={<>First Name <span style={{ fontSize: "120%" }}>*</span></>}
              placeholder="First Name"
              error={errors.firstName}
              value={localData.firstName}
              onChange={(e) => setDataByKey("firstName", e.target.value)}
            />
          </Col>
          <Col>
            <Input
              label={<>Last Name <span style={{ fontSize: "120%" }}>*</span></>}
              placeholder="Last Name"
              error={errors.lastName}
              value={localData.lastName}
              onChange={(e) => setDataByKey("lastName", e.target.value)}
            />
          </Col>
        </Row>

        <Row className="py-2">
          <Col>
            <Input
              label={<>Position in Company <span style={{ fontSize: "120%" }}>*</span></>}
              placeholder="e.g. CEO"
              error={errors.companyPosition}
              value={localData.companyPosition}
              onChange={(e) => setDataByKey("companyPosition", e.target.value)}
            />
          </Col>
          <Col>
            <Input
              label={<>Phone Number <span style={{ fontSize: "120%" }}>*</span></>}
              placeholder="e.g. 03330124819"
              error={errors.phoneNumber}
              value={localData.phoneNumber}
              onChange={(e) => setDataByKey("phoneNumber", e.target.value)}
            />
          </Col>
        </Row>

        <Row className="py-2">
          <Col>
            <Input
              type="email"
              label={<>Email Address <span style={{ fontSize: "120%" }}>*</span></>}
              placeholder=""
              error={errors.emailAddress}
              value={localData.emailAddress}
              onChange={(e) => setDataByKey("emailAddress", e.target.value)}
            />
          </Col>
          <Col />
        </Row>
      </div>
      <div className="flex-grow-1" />
      <section className="d-flex justify-content-between mt-3">
        <button type="button" className="blue-button-teams green-btn" onClick={stepBackward}>
          Back
        </button>
        <span className="py-1 text-danger">{Object.values(errors).some(err => !!err) && "Some errors were found, please correct above."}&nbsp;</span>
        <button type="submit" className="blue-button-teams green-btn">
          Next
        </button>
      </section>
    </form>
  );
}
