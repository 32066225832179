import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import FormatDate from "../../Util/FormatDate";
import AdjustmentsModal from '../../AdjustmentsModal';

const DataCheck = ({ selectedRouteID, availableRoutes, loadingDate, portID, portOptions,  portRowLabel, portModelID, portsModalOptions, invoiceCurrencyID, currencyOptions, incotermID, incotermsOptions, adjustments, controlledGoodsType, preferenceCheck, isLoadAnImport, setShowCheckModal, displayCheckModal }) => {
    const formRef = useRef();
    const [showAdjustmentsModal, setShowAdjustmentsModal] = useState(false);
    const showAdjustmentsLink = adjustments?.length > 0;

    const fieldStatus_Warning = 'warningHighlight';
    const fieldStatus_Error = 'invalidHighlight';
    
    const getValueFromID = (options, id) =>{  
        const selectedOption = options?.find(option => parseInt(option.id) === parseInt(id));
        return selectedOption ? selectedOption.data : null;
    }

    const validRoute = selectedRouteID && availableRoutes;
    const validPort = portID && portOptions;
    
    const validPortModel = portModelID && portsModalOptions;
    const validInvoiceCurrency = invoiceCurrencyID && currencyOptions;
    const validIncoterm = incotermID && incotermsOptions;

    const controlledGoodsTypeStatus = !controlledGoodsType ? fieldStatus_Error : controlledGoodsType === 'Y' ? fieldStatus_Warning : '';
    const controlledGoodsTypeValue = !controlledGoodsType ?  '' : controlledGoodsType === 'Y' ? 'Controlled' : 'Non-Controlled';

    const freightChargeAdjustment = adjustments?.find(adjustment => adjustment.name === 'freightCharges');
    const freightChargeAdjustmentAmount = freightChargeAdjustment ? freightChargeAdjustment.amount !== '' ? parseFloat(freightChargeAdjustment.amount) : 0 : 0;

    const incotermsWithRequiredFreightCharge = ['FCA', 'EXW', 'FAS', 'FOB'];
    const incotermValueFromID = getValueFromID(incotermsOptions, incotermID);
    const doesIncotermRequireFreightCharge = incotermsWithRequiredFreightCharge.some(incotermWithFreightCharge => incotermWithFreightCharge === incotermValueFromID);
    
    const adjustmentsInvalid = freightChargeAdjustmentAmount === 0 && doesIncotermRequireFreightCharge;

    const handleCheckClick = () => {
        setShowCheckModal(true)
    }

    return (
        <Container fluid>
            <div className="bp-section-heading">Data Check</div>
            <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                <div className={ `bp-table-grid-header bp-grid-default ${ !validRoute ? fieldStatus_Error : '' }` }>Route</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{ validRoute && getValueFromID(availableRoutes, selectedRouteID) }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !loadingDate ? fieldStatus_Error : '' }` }>Loading Date</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{loadingDate && FormatDate(loadingDate)}</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !validPort ? fieldStatus_Error : '' }` }>{ portRowLabel }</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{ validPort && getValueFromID(portOptions, portID) }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !validPortModel ? fieldStatus_Error : '' }` }>Port Model</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{ validPortModel && getValueFromID(portsModalOptions, portModelID) }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !validInvoiceCurrency ? fieldStatus_Error : '' }` }>Invoice Currency</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{ validInvoiceCurrency && getValueFromID(currencyOptions, invoiceCurrencyID) }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !validIncoterm ? fieldStatus_Error : '' }` }>Incoterm</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{ validIncoterm && incotermValueFromID }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ adjustmentsInvalid ? fieldStatus_Error : '' }` }>Adjustments</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>
                    {
                        showAdjustmentsLink ? <button class="button-text-link view-notes-button" onClick={(e) => {setShowAdjustmentsModal(true)}} title="Click to view all adjustments">View</button> : ''
                    }</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ controlledGoodsTypeStatus }` }>Type</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{controlledGoodsTypeValue}</div>
                <div className={ `bp-table-grid-header bp-grid-default ${ !preferenceCheck ? fieldStatus_Error : '' }` }>Preference</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>
                    <button class="button-text-link view-notes-button" title="Click to check product preferences" onClick={displayCheckModal ? handleCheckClick : null}>Check</button>
                </div>
            </div>
            
            <AdjustmentsModal 
                size='md'
                show={showAdjustmentsModal}
                onHide={() => {setShowAdjustmentsModal(false)}}
                showButtons
                viewOnly={true}
                ref={formRef}
            />
        </Container>
    );
};

export default DataCheck;