import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import CheckModalComponent from "./CheckModalComponent";

const CheckModal = ({
  showModal,
  setShowModal,
  totalValue,
  checkModalData,
  invoiceCurrency,
  validPreferenceOptions,
  statementMadeByOptions
}) => {
  //================================================================================================
  // States and variables
  const [EUState, setEUState] = useState({
    rexNumber: "",
    validPreferenceEU: 0,
    statementMadeByEU: 0,
    rexNumberError: "",
    validPreferenceEUError: "",
    statementMadeByEUError: "",
    rexNumberRequired: false,
  });

  const [EUStateInitial, setEUStateInitial] = useState({
    rexNumber: "",
    validPreferenceEU: 0,
    statementMadeByEU: 0,
    rexNumberRequired: false
  });

  const [thirdCountryState, setThirdCountryState] = useState({
    validPreference3rdCountry: 0,
    statementMadeBy3rdCountry: 0,
    directTransportRule: "",
    validPreference3rdCountryError: "",
    statementMadeBy3rdCountryError: "",
    directTransportRuleError: "",
  });

  const [thirdCountryStateInitial, setThirdCountryStateInitial] = useState({
    validPreference3rdCountry: 0,
    statementMadeBy3rdCountry: 0,
    directTransportRule: "",
  });

  const [EUOrigins, setEUOrigins] = useState([]);
  const [thirdCountryOrigins, setThirdCountryOrigins] = useState([]);

  const validPreferenceTooltipText = `Please confirm the preference is shown in the following format:

The exporter of the products covered by this document (Exporter Reference No ... (2)) declares that,
except where otherwise clearly indicated, these products are of ... (3) preferential origin.
…………………………………………………………………. (4) (Place and date)
……………………………………………………………........ (Name of the exporter)`;

  //================================================================================================
  // useEffects

  useEffect(() => {
    try {
      if (checkModalData.euCountries.length > 0) {
        setEUOrigins([
          ...new Set(checkModalData.euCountries.map((country) => country)),
        ]);

        // Set rexNumberRequired to true if invoiceCurrency is GBP and totalValue is > 5500 or if invoiceCurrency is EUR and totalValue is > 6000
        if (
          (invoiceCurrency === "GBP" && totalValue > 5500) ||
          (invoiceCurrency === "EUR" && totalValue > 6000)
        ) {
          handleEUStateChange("rexNumberRequired", true);
          setEUStateInitial((prevState) => ({
            ...prevState,
            rexNumberRequired: true,
          }));
        } else {
          handleEUStateChange("rexNumberRequired", false);
        }
      } else setEUOrigins([]);
      
      if (checkModalData.thirdCountries.length > 0) {
        setThirdCountryOrigins([
          ...new Set(checkModalData.thirdCountries.map((country) => country)),
        ]);
      } else setThirdCountryOrigins([]);
    } catch (e) {
      console.log("ERROR", e);
      console.log(checkModalData);
    }
  }, [checkModalData]);

  //================================================================================================
  // Handlers
  const handleEUStateChange = (key, value) => {
    setEUState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleThirdCountryStateChange = (key, value) => {
    setThirdCountryState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCancelClick = () => {
    setEUState(EUStateInitial);
    setThirdCountryState(thirdCountryStateInitial);
    setAllErrorsToEmpty();
    setShowModal(false);
  };

  const setAllErrorsToEmpty = () => {
    setEUState((prevState) => ({
      ...prevState,
      rexNumberError: "",
      validPreferenceEUError: "",
      statementMadeByEUError: "",
    }));
    setThirdCountryState((prevState) => ({
      ...prevState,
      validPreference3rdCountryError: "",
      statementMadeBy3rdCountryError: "",
      directTransportRuleError: "",
    }));
  };

  const handleConfirm = () => {
    let isValid = false;
    let isEUValid = false;
    let is3rdCountryValid = false;

    setAllErrorsToEmpty();
    if (EUOrigins.length > 0) {
      const validStatementEU = EUState.validPreferenceEU;
      const statementMadeByEU = EUState.statementMadeByEU;
      const rexNumber = EUState.rexNumber;

      if(validStatementEU == 3 && statementMadeByEU == 2 && !EUState.rexNumberRequired) {
        isEUValid = true;
      } 
      if (validStatementEU == 3 && statementMadeByEU == 2) {
        if (!rexNumber && EUState.rexNumberRequired) {
          setEUState((prevState) => ({
            ...prevState,
            rexNumberError: "Rex Number is required",
          }));
        }
        else{
          isEUValid = true;
        }
      } 
      else {
        if (validStatementEU != 3) {
          setEUState((prevState) => ({
            ...prevState,
            validPreferenceEUError: "Valid Preference Statement is required",
          }));
        }
        if (statementMadeByEU != 2) {
          setEUState((prevState) => ({
            ...prevState,
            statementMadeByEUError: "Statement made by is required",
          }));
        }
      }
    }
    else isEUValid = true;

    if (thirdCountryOrigins.length > 0) {
      const validPreference3rdCountry = thirdCountryState.validPreference3rdCountry;
      const statementMadeBy3rdCountry = thirdCountryState.statementMadeBy3rdCountry;
      const directTransportRule = thirdCountryState.directTransportRule;

      if (!validPreference3rdCountry || !statementMadeBy3rdCountry) {
        !validPreference3rdCountry && handleThirdCountryStateChange("validPreference3rdCountryError", "Valid Preference Statement is required");
        !statementMadeBy3rdCountry && handleThirdCountryStateChange("statementMadeBy3rdCountryError", "Statement Made Out By is required");
      } else {
        if (validPreference3rdCountry == 3 && statementMadeBy3rdCountry == 2) {
          is3rdCountryValid = true;
        } else if (!directTransportRule) {
          handleThirdCountryStateChange("directTransportRuleError", "MRN is required");
        }
        else{
          is3rdCountryValid = true;
        }
      }
    }
    else{
      is3rdCountryValid = true;
    }

    isValid = isEUValid && is3rdCountryValid;
    if (isValid) {
      setAllErrorsToEmpty();
      setEUStateInitial(EUState);
      setThirdCountryStateInitial(thirdCountryState);
      setShowModal(false, true);
    }
  };

  //================================================================================================
  // Render functions
  const showEUPreference = () => {
    return (
      <>
        <h4>EU Preference</h4>
        <p className="checkmodal-container__origins">
          Origins: {EUOrigins.join(", ")}
        </p>

        <CheckModalComponent
          labelText={"Valid Preference Statement"}
          hoverText={validPreferenceTooltipText}
          value={EUState.validPreferenceEU}
          name={"validPreference"}
          setValue={(value) => handleEUStateChange("validPreferenceEU", value)}
          selectOptions={validPreferenceOptions}
          inputType={"select"}
          errorMessage={EUState.validPreferenceEUError}
        />

        <CheckModalComponent
          labelText={"Statement made out by"}
          hoverText={
            "Confirm if the 'Importer' or 'Exporter' has made out the preference statement"
          }
          value={EUState.statementMadeByEU}
          name={"statementMadeByEU"}
          setValue={(value) => handleEUStateChange("statementMadeByEU", value)}
          selectOptions={statementMadeByOptions}
          inputType={"select"}
          errorMessage={EUState.statementMadeByEUError}
        />

        <CheckModalComponent
          labelText={"Rex Number"}
          hoverText={
            "A REX number is required for consignments above £ 5,500 or € 6,000. Use the 'REX Validation' option to validate the REX Number entered."
          }
          inputType={"input"}
          name="rexNumber"
          inputId="rexNumber"
          value={EUState.rexNumber}
          setValue={(value) => handleEUStateChange("rexNumber", value)}
          inputPlaceholder={!EUState.rexNumberRequired ? "Not Required" : "Rex Number Required"}
          disabled={!EUState.rexNumberRequired}
          errorMessage={EUState.rexNumberError}
        />
        <p className="checkmodal-container__total_value">
          <a
            href="https://ec.europa.eu/taxation_customs/dds2/eos/rex_validation.jsp?Lang=en"
            target="_blank"
            className={!EUState.rexNumberRequired || EUState.rexNumber === '' ? "checkmodal-container__disabled_rex_validation_link checkmodal-container__disabled_element" : ''}
          >
            REX Validation
          </a>
        </p>
      </>
    );
  };

  const show3rdCountryPreference = () => {
    return (
      <>
        <h4>3rd Country Preference</h4>
        <p className="checkmodal-container__origins">
          Origins: {thirdCountryOrigins.join(", ")}
        </p>

        <CheckModalComponent
          labelText={"Valid Preference Statement"}
          hoverText={
            "Confirm you have checked that the preference statement for the above origins."
          }
          value={thirdCountryState.validPreference3rdCountry}
          name={"validPreference3rdCountry"}
          setValue={(value) =>
            handleThirdCountryStateChange("validPreference3rdCountry", value)
          }
          selectOptions={validPreferenceOptions}
          inputType={"select"}
          errorMessage={thirdCountryState.validPreference3rdCountryError}
        />

        <CheckModalComponent
          labelText={"Statement made out by"}
          hoverText={
            "Confirm if the 'Importer' or 'Exporter' has made out the preference statement"
          }
          value={thirdCountryState.statementMadeBy3rdCountry}
          name={"statementMadeBy3rdCountry"}
          setValue={(value) =>
            handleThirdCountryStateChange("statementMadeBy3rdCountry", value)
          }
          selectOptions={statementMadeByOptions}
          inputType={"select"}
          errorMessage={thirdCountryState.statementMadeBy3rdCountryError}
        />

        <CheckModalComponent
          labelText={"Direct Transport Rule"}
          hoverText={
            "Enter the Transit MRN used to claim preference. This will be mapped across to the Customs Software"
          }
          inputType={"input"}
          name="directTransportRule"
          inputId="directTransportRule"
          value={thirdCountryState.directTransportRule}
          setValue={(value) =>
            handleThirdCountryStateChange("directTransportRule", value)
          }
          inputPlaceholder="Transit MRN"
          errorMessage={thirdCountryState.directTransportRuleError}
        />
      </>
    );
  };

  //================================================================================================
  // Return statement
  return (
    <>
      <Modal
        animation={false}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        style={{ padding: "10px" }}
      >
        <div className="checkmodal-container">
          <p className="checkmodal-container__total_value">
            Total Value ({invoiceCurrency}) {" "}
            {totalValue.toLocaleString("en-GB", {
              style: "currency",
              currency: invoiceCurrency ? invoiceCurrency : "GBP",
            })}
          </p>
          {EUOrigins.length > 0 && showEUPreference()}
          {thirdCountryOrigins.length > 0 && show3rdCountryPreference()}
          <div className="d-flex flex-row mt-4 justify-content-between full-width-container bottom-0">
            <button
              className="blue-button-teams green-btn"
              onClick={() => handleCancelClick()}
              style={{ fontSize: "1.25rem" }}
            >
              Cancel
            </button>
            <button
              className="blue-button-teams"
              onClick={() => handleConfirm()}
              style={{ fontSize: "1.25rem" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CheckModal;