import React, { useState } from "react";
import { Container } from "react-bootstrap";
import copyIcon from '../../../images/copyIcon.png';

const RevenuePayable = ({ newLoadQaVat }) => {
    const fieldStatus_Error = 'invalidHighlight';

    const [buttonText, setButtonText] = useState("Copy Ref");

    const handleCopyRef = () => {
      console.log('Revenue Payable  - copy customs duties');
      setButtonText("Copied");
    }

    const fieldStatus = !newLoadQaVat.vatValid ? fieldStatus_Error : '';

    return (
        <Container fluid>
            <div className="bp-section-heading">Revenue Payable</div>
            <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                <div className="bp-table-grid-header bp-grid-default">Total Liability (GBP)</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>-</div>
                <div className={ `bp-table-grid-header bp-grid-default customs-duties ${fieldStatus_Error}` }>
                    <span>Customs Duties</span>
                    <button onClick={handleCopyRef} title={buttonText} className="btn_submitted_copy_ref">
                        <img src={copyIcon} alt="copy icon" />
                    </button>
                </div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>Importers Deferment</div>
                <div className={ `bp-table-grid-header bp-grid-default ${fieldStatus}` }>VAT</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{newLoadQaVat.vatMessage}</div>
            </div>
        </Container>
    );
};

export default RevenuePayable;