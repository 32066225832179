import { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useStepper } from "../Stepper";
import { LoadIcon } from "../Util/Loaders";
import { postRegistrationData, postInternalRegistrationData } from "./../apiCalls";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import { useNavigate } from "react-router-dom";
//import { addClient } from "./_RegController"
import { StoreContext } from "../Store";
//import { useSecurity } from "../Security/security";
import ExternalView from "./ExternalView";
import InternalView from "./InternalView";

export default function SmartPack() {
  const { stepBackward, setStepsDisabled, localData, setDataByKey, activeStepKey } = useStepper();
  const [isLoading, setLoading] = useState(false);
  //const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [clicks, setClicks] = useState(0);
  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  //const [passwordText, setPasswordText] = useState("")
  //const [showDupText, setshowDupText] = useState(false)
  const [error, setError] = useState("");
  const [disableNext, setDisableNext] = useState(true);
  const [fileUpload, setFileUpload] = useState(null);
  const [emailSelected, setEmailSelected] = useState(false);
  // const [disableEmail, setDisableEmail] = useState(false);
  const navigate = useNavigate()

  const store = useContext(StoreContext);

  const isTokenAvail = useMemo(() => sessionStorage.getItem('token'), []);

  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "smart" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "4. SmartPack", {
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  useEffect(() => {
    isTokenAvail ? setDisableNext(!(fileUpload || emailSelected)) : setDisableNext(false)
    setDataByKey('emailSelected', emailSelected);
    setDataByKey("standingAuth", fileUpload);
  }, [fileUpload, emailSelected], localData.standingAuth, isTokenAvail)

  function addFile(e) {
    const file = e.target.files[0];
    if (file.name.slice(-3).toLowerCase() === "pdf") {
      setFileUpload(file);
    }
    else {
      setError("The document type will not be accepted.");
      return
    }
  }

  function removeFile() {
    setFileUpload(null);
  }

  const handleEmailSelected = () => {
    fileUpload ? setEmailSelected(true) : setEmailSelected((prev) => !prev)
  }

  const setErrorEmpty = () => {
    setError('')
  }

  const handleNext = async () => {
    if (!(emailSelected) && !(fileUpload) && isTokenAvail) {
      setError("At least one option to be selected");
    }
    else {
      setClicks((preCount) => preCount + 1);
      setStepsDisabled(true);
      setLoading(true);

      try {
        const applicationAuthorized = !(localData.dutyDefermentNumber === undefined || localData.dutyDefermentNumber === '');

        const fd = new FormData();

        const data = {
          firstName: localData.firstName,
          lastName: localData.lastName,
          companyPosition: localData.companyPosition,
          phoneNumber: localData.phoneNumber,
          emailAddress: localData.emailAddress,
          eoriNumber: localData.eoriNumber,
          vatNumber: localData.vatNumber,
          gbEntity: localData.gbEntity,
          companyName: localData.companyName,
          formation: localData.formation,
          companyCategory: localData.companyCategory,
          companyRegNumber: localData.companyRegNumber,
          building: localData.building,
          street: localData.street,
          town: localData.town,
          county: localData.county,
          postcode: localData.postcode,
          country: localData.country,
          building2: localData.building2,
          street2: localData.street2,
          town2: localData.town2,
          county2: localData.county2,
          postcode2: localData.postcode2,
          country2: localData.country2,
          building3: localData.building3,
          street3: localData.street3,
          town3: localData.town3,
          county3: localData.county3,
          postcode3: localData.postcode3,
          country3: localData.country3,
          paymentName: localData.paymentName,
          paymentLastName: localData.paymentLastName,
          paymentPhone: localData.paymentPhone,
          paymentEmail: localData.paymentEmail,
          monthlyDecs: localData.monthlyDecs,
          hasDda: localData.hasDda,
          dutyDefermentNumber: localData.dutyDefermentNumber,
          authorized: applicationAuthorized
        };

        fd.append('data', JSON.stringify(data));
        fd.append('existingCustomer', false)
        fd.append('emailSelected', localData.emailSelected);
        fd.append('sa21', localData?.standingAuth);

        let response = isTokenAvail ? await postInternalRegistrationData(fd) : await postRegistrationData(fd);

        if (response?.status === "Registration created") {
          console.log("Registration is WLP", store?.isWLP[0]);// to test the isWLP in dev  
          (store?.isWLP[0]) ? navigate("/main") : navigate("/EpoApplications");
        }
        if (response.duplicate === true) {
          return response.error
        }
        if (!(isTokenAvail) && !(response.duplicate)) {
          window.location.href = response.redirect_uri;
        }
      } catch {
        setLoading(false);
        setStepsDisabled(false);
      }
    }
  };

  return (<>
    {isTokenAvail ?
      <InternalView
        addFile={addFile}
        removeFile={removeFile}
        handleEmailSelected={handleEmailSelected}
        fileUpload={fileUpload}
        emailSelected={emailSelected}
        setError={setErrorEmpty} /> : <ExternalView />}
    <section className="d-flex justify-content-between mt-3">
      <button type="button" className="blue-button-teams green-btn" onClick={stepBackward}>
        Back
      </button>
      <span className="py-1 text-danger">{error}&nbsp;</span>
      <button type="button" disabled={clicks >= 1 || disableNext} className="blue-button-teams green-btn" onClick={handleNext}>
        {isLoading ? <>&nbsp;<LoadIcon className="text-white" style={{ width: '1.8rem', height: '1.8rem' }} />&nbsp;</> : "Continue"}
      </button>
    </section>
  </>);
}
