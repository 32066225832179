import React, { useState, useEffect, useContext } from 'react';
import { Container, OverlayTrigger, Popover, PopoverContent, Alert } from "react-bootstrap";
import { Input, Select, TextArea } from "../Input/Input.js";
import { getCompanyNameFromId, getNewLoad, postCustomaiteInfo, getCM8Details, postCm8CheckDuplicates } from "../apiCalls.js";
import { StoreContext } from "../Store.js";
import { Trash } from "@styled-icons/fa-solid";
import { useNavigate, useParams } from 'react-router-dom';
import LoadConfirmation from '../Newload/LoadConfirmation.js';
import ConfirmationModal from '../ConfirmationModal.js';
import isoDateConverter from '../isoDateConverter.js';
import Toast from '../Toast/Toast.js';


const CM8CreateNewLoad = () => {
    const store = useContext(StoreContext);
    const [disableInputs, setDisableInputs] = useState(false);
    const [error, setError] = useState('');
    const [documents, setDocuments] = useState([]);
    const [customer, setCustomer] = useState('11');
    const [direction, setDirection] = useState('');
    const [loadingDate, setLoadingDate] = useState('');
    const [jobRef, setJobRef] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [movements, setMovements] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [portalRef, setPortalRef] = useState('');
    const [showDuplicateModal, setShowDuplicateModal] = useState(false); //TODO: change to true for testing
    const [duplicateRecords, setDuplicateRecords] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const [status, setStatus] = useState({
      id: null,
      companyName: '',
      cabieStatus: '',
      cabieStatusValid: false,
      dbStatus: '',
      dbStatusValid: false,
      billableCustomerValid: '',
      billableCustomer: false,
      cm8Approved: '',
      cm8ApprovedValid: false
    });

    useEffect(() => {
      setDisableInputs(false);
      setIsSubmitted(true);
    }, [portalRef]);

    useEffect(() => {
      async function fetch() {
          try {
              const data = await getCM8Details(id);
                  setStatus({
                    id: data.id,
                    companyName: data.companyName,
                    cabieStatus: data.cabieStatus,
                    cabieStatusValid: data.cabieStatusValid,
                    dbStatus: data.dbStatus,
                    dbStatusValid: data.dbStatusValid,
                    billableCustomer: data.billableCustomer,
                    billableCustomerValid: data.billableCustomerValid,
                    cm8Approved: data.cm8Approved,
                    cm8ApprovedValid: data.cm8ApprovedValid
                  });
        
          } catch (error) {
            console.error('Error fetching NBP list:', error);
          }
      }
      
      fetch();
      
  }, [id]);

    const handleChange = (e) => {
      const inputValue = e.target.value;
      setInvoiceNumber(inputValue);
      if (inputValue.length > 35) {
        setError('Cannot exceed 35 characters.');
      } else {
        setError('');
      }
    };

    const handleGetDocuments = () => {
      alert("Get documents from JIRA");
    };
  
    const cancelAction = () => {
      navigate(`/CM8Clients/`)
    };

    const populateDuplicateRecords = (response) => {
      //iterate over response
      const duplicates = [];
      for (const record of response) {
        const duplicate = {
          portalRef: record.portalRef,
          date: record.loadDate,
          status: record.jobStatus
        }
        duplicates.push(duplicate);
      }
      setDuplicateRecords(duplicates);
      setShowDuplicateModal(true);
    }

    const checkCM8Duplicates = async () => {
      const fdCm8DupCheck = new FormData();
      fdCm8DupCheck.append('companyId', id);
      fdCm8DupCheck.append('jobref', jobRef);
      fdCm8DupCheck.append('invoicenumber', invoiceNumber);
      const responseCm8DupCheck = await postCm8CheckDuplicates(fdCm8DupCheck); //postCm8CheckDuplicates

      //check the first element in responseCm8DupCheck, and then check if the key 'duplicate' is true. If it is, then call a function called populateDuplicateRecords(response)
      if (responseCm8DupCheck[0].duplicate) {
        populateDuplicateRecords(responseCm8DupCheck);
      }
      else submitLoadToCustomaite();
    }
  
    const submitLoadToCustomaite = async () => {
      // alert("submitLoadToCustomaite");
      const fd = new FormData(); 
      fd.append('invoiceCustomer', customer);
      fd.append('direction', direction);
      fd.append('date', loadingDate);
      fd.append('jobref', jobRef);
      fd.append('invoicenumber', invoiceNumber);
      fd.append('companyId', id);
      fd.append('draft', 1);
      fd.append('additionalInfo', additionalInfo);
      for (const file of documents) {
        fd.append("documents", file.file);
      }
      
      setDisableInputs(true);
      const response = await postCustomaiteInfo(fd); 

      //check if response.contains an error key. If it does, print the error value
      if (response.error) {
        setShowToast({toastTitle: 'CM8 Load Error', toastBody: 'Failed to create CM8 Load'});
        setDisableInputs(false);
      }

      //check if response.completed exists and if contains the string "Inserted". if it does, update the portalRef state to response.transferid
      if (response?.completed == 'Inserted') {
        setPortalRef(response.transferid);
      }
      
    };

  const addFiles = async (files) => {
    const newDocuments = [];
  
    // Function to generate a hash for a file's content
    const generateFileHash = async (file) => {
      const arrayBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    };
  
    // Function to generate a new file name if a duplicate is found
    const generateNewFileName = (name, existingNames) => {
      let newName = name;
      let counter = 1;
      const extension = name.split('.').pop();
      const baseName = name.substring(0, name.lastIndexOf('.'));
      while (existingNames.includes(newName)) {
        newName = `${baseName}_${counter}.${extension}`;
        counter++;
      }
      return newName;
    };
  
    // Get existing file hashes and names
    const existingHashes = await Promise.all(documents.map(doc => generateFileHash(doc.file)));
    const existingNames = documents.map(doc => doc.Name);
  
    for (const f of files) {
      const fileHash = await generateFileHash(f);
      let fileName = f.name;
  
      //if the filename is the same and the content is the same, then it is a duplicate
      if (existingHashes.includes(fileHash) && existingNames.includes(fileName)) {
        console.log('file name and content are the same.........')
        setShowToast({toastTitle: 'Duplicate File', toastBody: "One or more files haven't been added as they are duplicates."});
        continue;
      }
      else if (existingNames.includes(fileName)) {
        fileName = generateNewFileName(fileName, existingNames);
      }
  
      if (f.name.slice(-3).toLowerCase() === "xls" || f.name.slice(-3).toLowerCase() === "pdf" ||
        f.name.slice(-3).toLowerCase() === "png" || f.name.slice(-3).toLowerCase() === "jpg" ||
        f.name.slice(-4).toLowerCase() === "jpeg" || f.name.slice(-4).toLowerCase() === "xlsx") {
          const newDocument = {
            id: documents.length + newDocuments.length + 1,
            isSelected: false,
            Name: fileName,
            DocType: `${f.name.split('.').pop()} file`,
            allowDelete: true,
            file: f
          }
          newDocuments.push(newDocument);
          existingNames.push(fileName); // Add the new name to the existing names list
      } else {
        setError("We could not process your file, please review the file and file type.");
      }
    }
  
    setDocuments([...documents, ...newDocuments]);
  };
  
    const removeFile = (file) => {
      setDocuments(documents.filter(document => document.id !== file.id));
    }
  
    const noDocumentsLoaded = documents === null || documents.length === 0;

    const enableFileRetrivalFromJIRA = false;
  
    useEffect(() => {
      const fetch = async () => {
          const data = await getCompanyNameFromId(store.clientID[0])//TODO: change from store to a props
          // store.clientIDname[1](data[0].companyName)
      }
      if (store.clientID[0])//TODO: change from store to a props
      fetch();
  
      setDisableInputs(false);
  
    }, [store.clientID[0]])//TODO: change from store to a props

    useEffect(() => {
      const fetchData = async () => {
        const data = await getNewLoad('movement');
        setMovements(data);
      }
      fetchData();
      setIsSubmitted(false);
    }, []);

    const isSubmitDisabled = () => {
      if ( 
          customer === "" || 
          direction === "" || 
          invoiceNumber === "" || 
          jobRef === "" || 
          noDocumentsLoaded ||
          loadingDate === "") {
        return true;
      }
      return false;
    }

    const handleNewLoadAction = () => {
      navigate("/CM8Clients/", { replace: true });
    }

    const handleListLoadAction = () => {
      navigate("/ListLoads");
    }

    const convertDate = (recordDate) => {
      console.log('recordDate: ', recordDate);
      if(!recordDate) return '(No Loading Date)';
      return isoDateConverter(recordDate, true, false, true);
    }

    const toastContent = () => {
      return (
        <Toast className="error-toast">
        <Alert style={{ maxWidth: "35rem", backgroundColor: "#E4E3ED"}} show={showToast}>
          <button
            className="btn-close position-absolute top-0 end-0 p-3"
            onClick={() => setShowToast(false)}
          />
          <div
            className="floating-box-header-newload align-items-center"
            style={{ margin: "0px 30px", padding: 0 }}
          >
            <span>{showToast.toastTitle}</span>
          </div>
          <p
            style={{ fontSize: "100%", fontWeight: 500 }}
            className="text-danger"
          >
            {showToast.toastBody}
          </p>
        </Alert>
      </Toast>
      )
    }

    return (
      isSubmitted ? 
        <LoadConfirmation newLoadAction={handleNewLoadAction} listLoadAction={handleListLoadAction} titleText="Submitted to CM8" bodyText="has been submitted to CM8" portalRef={portalRef}/> :
        (
          <Container fluid className="pt-4">
            {toastContent()}
            <ConfirmationModal
              header="Duplicates detected"
              show={showDuplicateModal}
              onHide={() => setShowDuplicateModal(false)}
              onConfirm={() => submitLoadToCustomaite()}
              hideCancel={false}
              animation={false}
              centered
            >
              <p>Duplicate combination of the following has been detected.</p>
              <ul>
                  <li>Customer</li>
                  <li>Job Reference</li>
                  <li>Invoice number</li>
              </ul>
              <p>Duplicate load details:</p>
              <ul>
                {
                  duplicateRecords.map((record) => (
                    <li key={record.portalRef}><span>{record.portalRef} - </span><span>{convertDate(record.date)}</span> - <span>({record.status})</span></li>
                  ))
                }
              </ul>

            </ConfirmationModal>
            <Container fluid className="">
              <Container fluid="lg" className="mb-4 semi-fluid-container">
                <section className="floating-box-newload" style={{ width: "70%", margin: "0 auto" }}>
                  <div className="floating-box-header-newload">
                    <span>Create CM8 Load</span>
                  </div>
                  <div className="section-group">
                    <div className="section-group" style={{ flexBasis: "61%" }}>
                      <div className="section">
                        <p className="title">Load Details</p>
                        <div className="content">
                          <div className="form-field">
                              <Input
                                label="Invoice Customer"
                                name="invoiceCustomer"
                                type="text"
                                      value={(status.companyName && status.id) ? `${status.companyName} (${status.id})` : `-`}
                                required
                                disabled
                              />
                          </div>
                          <div className="form-field">
                              <Select
                                label="Movement"
                                name="direction"
                                data={direction}
                                setData={setDirection}
                                options={movements}
                                required
                                disabled={disableInputs}
                                info={
                                  <OverlayTrigger
                                    placement="right"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                      <Popover style={{ minWidth: "15rem" }}>
                                        <PopoverContent>
                                          <h6>Movement Type</h6>
                                          <p>
                                            A movement type is required before you can begin
                                            the process of entering a New Load.
                                          </p>
                                        </PopoverContent>
                                      </Popover>
                                    }
                                  >
                                    <div className="important-info"></div>
                                  </OverlayTrigger>
                                }
                              />
                          </div>
                          <div className="form-field">
                            <Input
                              label="Loading Date"
                              name="date"
                              type="date"
                              //value={`To set - loadingDate`}
                              required
                              onChange={(e) => setLoadingDate(e.target.value)}
                              disabled={disableInputs}
                            />
                          </div>
                          <div className="form-field">
                              <Input
                                label="Job Reference"
                                name="jobref"
                                type="text"
                                required
                                //value={`To set - jobRef`}
                                onChange={(e) => setJobRef(e.target.value)}
                                disabled={disableInputs}
                              />
                          </div>
                          <div className="form-field">
                              <Input
                                label="Invoice Number"
                                name="invoicenumber"
                                type="text"
                                required
                                //value={`To set - invoiceNumber`}
                                  onChange={(e) => handleChange(e)} 
                                error={error}
                                disabled={disableInputs}
                              />
                          </div>
                          {
                            enableFileRetrivalFromJIRA && 
                              <div className="form-field" style= {{ justifyContent: "flex-end" }}>
                                <button className="stepper-button" style= {{ width: "auto" }} onClick={() => {handleGetDocuments()}} disabled={disableInputs}>Get documents</button>
                              </div>
                          }
                        </div>
                      </div>
                      <div className="section">
                        <div className="content" style={{ margin: "0",  padding: "0" }}>
                          <div className="d-flex flex-column">
                            <label name="name" style={{borderRadius: '15px', cursor: 'pointer', textDecoration: "underline", color: "var(--eori-green)", margin: '.5rem 0 0 .75rem', display: disableInputs ? 'none' : 'inherit'}}>
                              <span>Upload document</span>
                              <input type="file" name="fileuploader" onChange={(e) => addFiles(e.target.files)} onClick={(e) => { e.target.value = null; setError("");}} accept=".pdf,.png,.jpeg,.xls,.xlsx" multiple />
                            </label>
                          </div>
                          <div className="documents-list">
                            {
                              noDocumentsLoaded ? 
                                <p className='no-docs-loaded'>No documents.</p> : 
                                <>
                                  {
                                    documents.map((data) => (
                                      <span className="document-line" key={data.id} title={data.Name}>
                                        <span className="document-line-group">
                                          <span className="text-nowrap" style={{textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            <span style={{ fontWeight: '500' }}>{data.Name}</span> 
                                            {
                                              data.DocType && <span style={{ marginLeft: '.5rem' }}>({data.DocType})</span>
                                            } 
                                          </span>
                                        </span>
                                        {
                                          (data.allowDelete) &&
                                            <button style={{appearance: 'none', border: 'none', backgroundColor: 'transparent'}} onClick={() => removeFile(data)} disabled={disableInputs}>
                                              <Trash width={"1rem"} height={"1rem"} style={{color: 'red'}} />
                                            </button>
                                        }
                                      </span>
                                    ))
                                  }
                                </>
                            }
                          </div>
                          <p className='cm8_uploadedCount'>Uploaded: {documents.length}</p>
                        </div>
                      </div>
                    </div>
                    <div className="section" style={{ flexBasis: "37%" }}>
                        <div className="content">
                            <div className="form-field full-width textarea-with-instructions">
                            <TextArea
                                style={{ resize: "none", height: "248px" }}
                                label="Additional Information"
                                placeholder="Email or Text supporting shipment"
                                disabled={disableInputs}
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex pb-3 px-3 w-100 justify-content-between">
                  <button className="epo-continue-button" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} type="button" onClick={() => {cancelAction(true)}} disabled={disableInputs}>Cancel</button>
                  <button className="blue-button-newload" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} type="button" onClick={() => {checkCM8Duplicates()}} disabled={isSubmitDisabled() || disableInputs}>Submit</button>
                </div>
              </section>
            </Container>
          </Container>
        </Container>
      )
    )
}

export default CM8CreateNewLoad;
