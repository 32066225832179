import { Input, Select } from "../../Input/Input";
import {
  OverlayTrigger,
  Popover,
  PopoverContent,
} from "react-bootstrap";
import { InfoCircle } from "@styled-icons/fa-solid";

const CheckModalComponent = ({
  labelText,
  hoverText,
  name = '',
  selectOptions = "",
  inputType = "",
  inputId = '',
  inputPlaceholder = '',
  setValue = '',
  value = '',
  errorMessage = '',
  disabled = false,
}) => {
  return (
    <div className="checkmodal-container__data_container">
      <p className="checkmodal-container__info">
        {`${labelText}  `}
        {disabled ? (
          <InfoCircle
          width="1.3rem"
          style={{
            marginRight: "10px",
            color: disabled ? "#A9BED4" : "#1565C0",
            cursor: disabled ? "" : "pointer",
            backgroundColor: "rgba(247,247,247, 0.1)",
          }}
        />
        ) : (
          <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={
            <Popover style={{ minWidth: "15rem" }}>
              <PopoverContent>
                <p>{hoverText}</p>
              </PopoverContent>
            </Popover>
          }
        >
          <InfoCircle
            width="1.3rem"
            style={{
              marginRight: "10px",
              color: disabled ? "#A9BED4" : "#1565C0",
              cursor: disabled ? "" : "pointer",
              backgroundColor: "rgba(247,247,247, 0.1)",
            }}
          />
        </OverlayTrigger>
        )}
      </p>
      {inputType === "select" ? (
        <Select
          hideLabel={true}
          name={name}
          data={value}
          setData={(val) => setValue(val)}
          options={selectOptions}
          required={true}
          disabled={false}
          noBorders={true}
          classNameInputField="checkmodal-container__select"
          error={errorMessage}
        />
      ) : (
        <Input
            type="text"
            hideLabel={true}
            name={name}
            id={inputId}
            value={value}
            classNameInputField="checkmodal-container__select"
            noBorders={true}
            onChange={(e) => setValue(e.target.value)}
            placeholder={inputPlaceholder}
            required
            error={errorMessage}
            disabled={disabled}
          />
      )}
    </div>
  );
};

export default CheckModalComponent;
